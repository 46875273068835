import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import NotActive from "../assets/Not-Active.png";
import { Link } from "react-router-dom";
const Subcontractors = ({ selectedLetter }) => {
  const [subcontractors, setSubcontractors] = useState([]);
  const [expandedIds, setExpandedIds] = useState(new Set());

  const fetchSubcontractors = useCallback(() => {
    const queryParameters = {
      params: {
        letter: selectedLetter || "",
      },
    };

    console.log("Fetching subcontractors with:", selectedLetter);
    axios
      .get(`${process.env.REACT_APP_API_URL}/companies/subcontractors`, queryParameters)
      .then((response) => {
        console.log("Subcontractors fetched successfully:", response.data);
        setSubcontractors(response.data);
      })
      .catch((error) => {
        console.error("Failed to fetch subcontractors:", error);
      });
  }, [selectedLetter]);

  useEffect(() => {
    fetchSubcontractors();
  }, [fetchSubcontractors]);

  const toggleExpand = (id) => {
    const newSet = new Set(expandedIds);
    if (newSet.has(id)) {
      newSet.delete(id);
    } else {
      newSet.add(id);
    }
    setExpandedIds(newSet);
  };

  return (
    <div>
      <h2>Subcontractors</h2>
      <div className="terms-list">
        {subcontractors.map((subcontractor) => (
          <div
            key={subcontractor.id}
            className={`term-entry ${expandedIds.has(subcontractor.id) ? "open" : ""}`}
            onClick={() => toggleExpand(subcontractor.id)}
          >
            <h2 className="term-title"
              style={{ cursor: "pointer", position: "relative" }}
            >
              <span className="expand-toggle">
                {expandedIds.has(subcontractor.id) ? (
                  <span><i className="fa-solid fa-circle-chevron-up"></i></span>
                ) : (
                  <span><i className="fa-solid fa-circle-chevron-right"></i></span>
                )}
              </span>
              {subcontractor.name_en}
            </h2>
            {expandedIds.has(subcontractor.id) && (
              <div className="term-content" style={{ position: "relative" }}>
              {subcontractor.me_about && <p>{subcontractor.me_about}</p>}
              <i className="fas fa-home"></i> &nbsp;
              <Link to={`/companies/${subcontractor.id}#/companies/${subcontractor.id}`}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                My eDMAC Company Page
              </Link>
              {subcontractor.is_active === 0 && (
                <img
                  src={NotActive}
                  alt="Not Active Icon"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    width: 60,
                  }}
                />
              )}
            </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Subcontractors;
