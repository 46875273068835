import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import PartTypeMultiSelector from "./PartTypeSelector";
import NotActive from "../assets/Not-Active.png";
import { Link } from "react-router-dom";

const Manufacturer = ({ selectedLetter }) => {
  const [manufacturers, setManufacturers] = useState([]);
  const [expandedIds, setExpandedIds] = useState(new Set());
  const [selectedPartTypes, setSelectedPartTypes] = useState([]);
  const [partTypes, setPartTypes] = useState({});

  const fetchManufacturers = useCallback(() => {
    const partTypeQuery = selectedPartTypes.length > 0 ? selectedPartTypes.join(",") : "";

    console.log("Fetching manufacturers with:", selectedLetter, partTypeQuery);
    axios
      .get(`${process.env.REACT_APP_API_URL}/companies/manufacturer`, {
        params: {
          letter: selectedLetter || "",
          partTypes: partTypeQuery,
        },
      })
      .then((response) => {
        console.log("Manufacturers fetched successfully:", response.data);
        setManufacturers(response.data);
        response.data.forEach(manufacturer => fetchPartTypes(manufacturer.id));
      })
      .catch((error) => {
        console.error("Failed to fetch manufacturers:", error);
      });
  }, [selectedLetter, selectedPartTypes]);

  const fetchPartTypes = (manufacturerId) => {
    axios.get(`${process.env.REACT_APP_API_URL}/companies/${manufacturerId}/products`)
      .then(response => setPartTypes(prev => ({ ...prev, [manufacturerId]: response.data })))
      .catch(error => console.error("Failed to fetch part types for manufacturer ID:", manufacturerId, error));
  };

  useEffect(() => {
    fetchManufacturers();
  }, [fetchManufacturers]);

  const toggleExpand = (id) => {
    const newSet = new Set(expandedIds);
    newSet.has(id) ? newSet.delete(id) : newSet.add(id);
    setExpandedIds(newSet);
  };

  return (
    <div>
      <h2>Manufacturers</h2>
      <div className="alphabetic-filter filter-search">
        <div className="filter-search-title">Search By Part Type (Part Category) &nbsp;&nbsp;&nbsp;</div>
        <div className="filter">
          <PartTypeMultiSelector onSelectPartType={setSelectedPartTypes} />
        </div>
      </div>
      <div className="terms-list">
        {manufacturers.map((manufacturer) => (
          <div key={manufacturer.id} className={`term-entry ${expandedIds.has(manufacturer.id) ? "open" : ""}`}>
            <h2 className="term-title" onClick={() => toggleExpand(manufacturer.id)}>
              <span className="expand-toggle">
                {expandedIds.has(manufacturer.id) ? (
                  <span><i className="fa-solid fa-circle-chevron-up"></i></span>
                ) : (
                  <span><i className="fa-solid fa-circle-chevron-right"></i></span>
                )}
              </span>
              {manufacturer.name_en}
            </h2>
            {expandedIds.has(manufacturer.id) && (
              <div className="term-content" style={{ position: "relative" }}>
                {manufacturer.me_about && <p>{manufacturer.me_about}</p>}
                <i className="fas fa-home"></i> &nbsp;
                <Link to={`/companies/${manufacturer.id}`} style={{ color: "inherit", textDecoration: "none" }}>
                  My eDMAC Company Page
                </Link>
                {manufacturer.is_active === 0 && (
                  <img src={NotActive} alt="Not Active Icon" style={{ position: "absolute", bottom: 0, right: 0, width: 60 }} />
                )}
                <ul>
                  {partTypes[manufacturer.id] && partTypes[manufacturer.id].map(pt => <li key={pt.id}>{pt.val}</li>)}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Manufacturer;
