import React from "react";

const VideoComponent = ({ fileName, position }) => {
  return (
    <div style={{ width: "100%", height: "400px", overflow: "hidden", borderRadius: "5px" }}>
      <video
        loop
        autoPlay
        muted
        playsInline
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: `center ${position}`, // מיקום התוכן
        }}
      >
        <source src={fileName} type="video/mp4" />
        הדפדפן שלך לא תומך בתג וידאו.
      </video>
    </div>
  );
};

export default VideoComponent;
