import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Link as ScrollLink, Element } from "react-scroll";
import About from "./Companies/CompAbout";
import Products from "./Companies/CompProducts";
import Brands from "./Companies/CompBrands";
import ContactUs from "./Companies/CompContact";
import CompanyHeader from "./Companies/CompanyHeader";
import "./css/Companies.css"; // Ensure this path is correct

const Companies = () => {
  const { id: companyId } = useParams();
  const [showBrands, setShowBrands] = useState(false);

  return (
    <div className="companies-page">
      <CompanyHeader companyId={companyId} />
      <nav className="sub-nav">
        <ul>
          <li><ScrollLink to="about" smooth={true} duration={500} offset={-60}>About</ScrollLink></li>
          <li><ScrollLink to="products" smooth={true} duration={500} offset={-60}>Products</ScrollLink></li>
          {showBrands && (
            <li><ScrollLink to="brands" smooth={true} duration={500} offset={-60}>Brands</ScrollLink></li>
          )}
          <li><ScrollLink to="contact" smooth={true} duration={500} offset={-60}>Contact Us</ScrollLink></li>
        </ul>
      </nav>
      <Element name="about" className="section"><About companyId={companyId} /></Element>
      <Element name="products" className="section"><Products companyId={companyId} /></Element>
      {showBrands && (
        <Element name="brands" className="section">
          <Brands companyId={companyId} onBrandsLoaded={setShowBrands} />
        </Element>
      )}
      <Element name="contact" className="section"><ContactUs companyId={companyId} /></Element>
    </div>
  );
};

export default Companies;
