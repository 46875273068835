import React from 'react';
import './css/CapacitorTable.css';

const CapacitorTable = () => {
  const data = [
    {
      part: 'CL21A106K0QNNNE',
      quantity: '2,153,287 In Stock',
      price: '1: $0.1000',
      series: 'CL',
      package: '...',
      status: 'Active',
      capacitance: '10 µF',
      tolerance: '±10%',
      voltage: '16V',
      tempCoeff: 'X5R',
      opTemp: '-55°C ~ 85°C',
      features: '-',
      ratings: '-',
      applications: 'General Purpose',
      failureRate: '-',
      mounting: 'Surface Mount, MLCC',
      packageCase: '0805 (2012 Metric)',
      size: '0.079" L x 0.049" W (2.00mm x 1.25mm)',
      height: '-',
      thickness: '0.055" (1.40mm)',
      leadSpacing: '-',
    },
    {
      part: 'CL21A106K0QNNNG',
      quantity: '1,751,924 In Stock',
      price: '1: $0.1000',
      series: 'CL',
      package: '...',
      status: 'Active',
      capacitance: '10 µF',
      tolerance: '±10%',
      voltage: '16V',
      tempCoeff: 'X5R',
      opTemp: '-55°C ~ 85°C',
      features: '-',
      ratings: '-',
      applications: 'General Purpose',
      failureRate: '-',
      mounting: 'Surface Mount, MLCC',
      packageCase: '0805 (2012 Metric)',
      size: '0.079" L x 0.049" W (2.00mm x 1.25mm)',
      height: '-',
      thickness: '0.055" (1.40mm)',
      leadSpacing: '-',
    },
    {
      part: 'CL10A106M08NNNC',
      quantity: '1,127,175 In Stock',
      price: '1: $0.1000',
      series: 'CL',
      package: '...',
      status: 'Active',
      capacitance: '10 µF',
      tolerance: '±20%',
      voltage: '6.3V',
      tempCoeff: 'X5R',
      opTemp: '-55°C ~ 85°C',
      features: '-',
      ratings: '-',
      applications: 'General Purpose',
      failureRate: '-',
      mounting: 'Surface Mount, MLCC',
      packageCase: '0603 (1608 Metric)',
      size: '0.063" L x 0.031" W (1.60mm x 0.80mm)',
      height: '-',
      thickness: '0.035" (0.90mm)',
      leadSpacing: '-',
    },
    {
      part: 'CL21A106KPFNNNE',
      quantity: '563,487 In Stock',
      price: '1: $0.1000',
      series: 'CL',
      package: '...',
      status: 'Active',
      capacitance: '10 µF',
      tolerance: '±10%',
      voltage: '10V',
      tempCoeff: 'X5R',
      opTemp: '-55°C ~ 85°C',
      features: '-',
      ratings: '-',
      applications: 'General Purpose',
      failureRate: '-',
      mounting: 'Surface Mount, MLCC',
      packageCase: '0805 (2012 Metric)',
      size: '0.079" L x 0.049" W (2.00mm x 1.25mm)',
      height: '-',
      thickness: '0.053" (1.35mm)',
      leadSpacing: '-',
    },
    {
      part: 'GRM155R60J106ME05D',
      quantity: '161,488 In Stock',
      price: '1: $0.1000',
      series: 'GRM',
      package: '...',
      status: 'Active',
      capacitance: '10 µF',
      tolerance: '±20%',
      voltage: '6.3V',
      tempCoeff: 'X5R',
      opTemp: '-55°C ~ 85°C',
      features: '-',
      ratings: '-',
      applications: 'General Purpose',
      failureRate: '-',
      mounting: 'Surface Mount, MLCC',
      packageCase: '0402 (1005 Metric)',
      size: '0.039" L x 0.020" W (1.00mm x 0.50mm)',
      height: '-',
      thickness: '0.028" (0.70mm)',
      leadSpacing: '-',
    },
    {
      part: 'JMK107ABJ106MA-T',
      quantity: '1,448,498 In Stock',
      price: '1: $0.1000',
      series: 'M',
      package: '...',
      status: 'Active',
      capacitance: '10 µF',
      tolerance: '±20%',
      voltage: '6.3V',
      tempCoeff: 'X5R',
      opTemp: '-55°C ~ 85°C',
      features: '-',
      ratings: '-',
      applications: 'SMPS Filtering',
      failureRate: '-',
      mounting: 'Surface Mount, MLCC',
      packageCase: '0603 (1608 Metric)',
      size: '0.063" L x 0.037" W (1.60mm x 0.95mm)',
      height: '-',
      thickness: '0.037" (0.95mm)',
      leadSpacing: '-',
    },
  ];

  return (
    <div>
      <div className="search-entry">
        <input type="text" value="capacitor ceramic 10uF" readOnly />
        <button>Search</button>
      </div>
      <div className="results-info">
        <span>Showing 1 - 25 of 4,557</span>
        <select>
          <option>Sort By: Featured</option>
        </select>
      </div>
      
      {/* אלמנט עוטף עבור גלילה לרוחב */}
      <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
        <table>
          <thead>
            <tr>
              <th>Mfr Part #</th>
              <th>Quantity Available</th>
              <th>Price</th>
              <th>Series</th>
              <th>Package</th>
              <th>Product Status</th>
              <th>Capacitance</th>
              <th>Tolerance</th>
              <th>Voltage - Rated</th>
              <th>Temperature Coefficient</th>
              <th>Operating Temperature</th>
              <th>Features</th>
              <th>Ratings</th>
              <th>Applications</th>
              <th>Failure Rate</th>
              <th>Mounting Type</th>
              <th>Package / Case</th>
              <th>Size / Dimension</th>
              <th>Height - Seated (Max)</th>
              <th>Thickness (Max)</th>
              <th>Lead Spacing</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.part}</td>
                <td>{item.quantity}</td>
                <td>{item.price}</td>
                <td>{item.series}</td>
                <td>{item.package}</td>
                <td>{item.status}</td>
                <td>{item.capacitance}</td>
                <td>{item.tolerance}</td>
                <td>{item.voltage}</td>
                <td>{item.tempCoeff}</td>
                <td>{item.opTemp}</td>
                <td>{item.features}</td>
                <td>{item.ratings}</td>
                <td>{item.applications}</td>
                <td>{item.failureRate}</td>
                <td>{item.mounting}</td>
                <td>{item.packageCase}</td>
                <td>{item.size}</td>
                <td>{item.height}</td>
                <td>{item.thickness}</td>
                <td>{item.leadSpacing}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
  
};

export default CapacitorTable;
