import React, { useEffect, useState } from "react";
import axios from "axios";

const CompProducts = ({ companyId }) => {
  const [productTypes, setProductTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProductTypes = async () => {
      if (!companyId) {
        setError("No company ID provided");
        setLoading(false);
        return;
      }

      const url = `${process.env.REACT_APP_API_URL}/companies/${companyId}/products`;
      console.log("Fetching product types from:", url);

      try {
        const response = await axios.get(url);
        if (response.data.length > 0) {
          console.log("Product types fetched successfully:", response.data);
          setProductTypes(response.data);
          setError(null); // Clear any previous error
        } else {
          setError("No part types available"); // Specific message when no types are available
        }
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch product types:", error);
        setError("Failed to load product types"); // Generic error message
        setLoading(false);
      }
    };

    fetchProductTypes();
  }, [companyId]);

  if (loading) return <p>Loading...</p>;
  if (error)
    return (
      <div>
        <h2>Me Part Types (part category)</h2>
        <p>This is Company Main Product Page if available</p>
        <p>{error}</p>
      </div>
    );

  return (
   <>
      <div>
      <h2>Me Part Types (part category)</h2>
        <p>This is Company Main Product Page if available</p>
        <ul>
          {productTypes.map((type) => (
            <li key={type.id}>{type.val}</li>
          ))}
        </ul>
        {productTypes.length === 0 && <p>No part types available.</p>}
        <p style={{ color: "grey" }}>
          <i className="fa-solid fa-link"></i> Company Parts
        </p>
      </div>
    </>
  );
};

export default CompProducts;
