import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import PartTypeMultiSelector from "./PartTypeSelector";
import NotActive from "../assets/Not-Active.png";
import ECIA from "../assets/ecia.png";
import { Link } from "react-router-dom";

const Supplier = ({ selectedLetter }) => {
  const [suppliers, setSuppliers] = useState([]);
  const [partTypes, setPartTypes] = useState({});
  const [selectedPartTypes, setSelectedPartTypes] = useState([]);
  const [expandedIds, setExpandedIds] = useState(new Set());

  const fetchSuppliers = useCallback(async () => {
    const partTypeQuery = selectedPartTypes.length > 0 ? selectedPartTypes.join(",") : "";
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/companies/supplier`, {
        params: {
          letter: selectedLetter || "",
          partTypes: partTypeQuery,
        },
      });
      setSuppliers(response.data);
      response.data.forEach(supplier => {
        fetchPartTypes(supplier.id); // Fetch parts when suppliers are loaded
      });
    } catch (error) {
      console.error("Failed to fetch suppliers:", error);
    }
  }, [selectedLetter, selectedPartTypes]);

  const fetchPartTypes = (supplierId) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/companies/${supplierId}/products`)
      .then(response => {
        setPartTypes(prev => ({ ...prev, [supplierId]: response.data }));
      })
      .catch(error => {
        console.error("Failed to fetch part types for supplier ID:", supplierId, error);
      });
  };

  useEffect(() => {
    fetchSuppliers();
  }, [fetchSuppliers]);

  const toggleExpand = (id) => {
    const newSet = new Set(expandedIds);
    newSet.has(id) ? newSet.delete(id) : newSet.add(id);
    setExpandedIds(newSet);
  };

  return (
    <div>
      <h2>Suppliers</h2>
      <div className="alphabetic-filter filter-search">
        <div className="filter-search-title">Search By Part Type (Part Category) &nbsp;&nbsp;&nbsp;</div>
        <div className="filter">
          <PartTypeMultiSelector onSelectPartType={setSelectedPartTypes} />
        </div>
      </div>
      <div className="terms-list">
        {suppliers.map((supplier) => (
          <div key={supplier.id} className={`term-entry ${expandedIds.has(supplier.id) ? "open" : ""}`}>
            <h2 className="term-title" onClick={() => toggleExpand(supplier.id)}>
              <span className="expand-toggle">
                {expandedIds.has(supplier.id) ? (
                  <span><i className="fa-solid fa-circle-chevron-up"></i></span>
                ) : (
                  <span><i className="fa-solid fa-circle-chevron-right"></i></span>
                )}
              </span>
              {supplier.name_en}
            </h2>
            {expandedIds.has(supplier.id) && (
              <div className="term-content" style={{ position: "relative" }}>
                {supplier.me_about && <p>{supplier.me_about}</p>}
                <i className="fas fa-home"></i> &nbsp;
                <Link to={`/companies/${supplier.id}`} style={{ color: "inherit", textDecoration: "none" }}>
                  My eDMAC Company Page
                </Link>
                {supplier.is_active === 0 && (
                  <img src={NotActive} alt="Not Active Icon" style={{ position: "absolute", bottom: 0, right: 0, width: 60 }} />
                )}
                {supplier.ECIA_Member === 'X' && (
                  <img src={ECIA} alt="ECIA Member" style={{ position: "absolute", bottom: 0, right: 0, width: 80 }} />
                )}
                <ul>
                  {partTypes[supplier.id] && partTypes[supplier.id].map(pt => <li key={pt.id}>{pt.val}</li>)}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Supplier;
