// src/pages/Home.js
import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import VideoComponent from '../components/VideoComponent';
import videoFile from '../assets/Video/E.mp4';
import "../styles/Home.css";
import Image1 from "../assets/1.png";
import Image2 from "../assets/2.png";
import Image3 from "../assets/3.png";
function Home() {

  return (
    <div>
      <Helmet>
        <title>Home Page - My eDMAC</title>
        <meta
          name="description"
          content="Welcome to our homepage where you can find various products and services."
        />
      </Helmet>
      <div className="home">
        <VideoComponent fileName={videoFile} cropHeight="400px" position="top" />
        <section className="main-data">
          <div className="section-content">
            <div className="text">
              <span className="brand">My eDMAC</span> is your premier partner in
              the electronic industry, specializing in enhancing B2B services
              and streamlining processes. <br />
              With over 40 years of experience, we offer a comprehensive suite
              of services tailored to support the engineering and component
              engineering needs of your business.
            </div>
            <div className="image">
              <img src={Image1} alt="Description 1" />
            </div>
          </div>

          <div className="section-content">
            <div className="text">
              At <span className="brand">My eDMAC</span>, we excel in New
              Product Introduction (NPI), ensuring your innovations reach the
              market efficiently. <br />
              Our engineering services extend to crucial tasks such as Bill of
              Materials (BOM) and Configuration Management, enabling precise and
              organized project handling.
            </div>
            <div className="image">
              <img src={Image2} alt="Description 2" />
            </div>
          </div>

          <div className="section-content">
            <div className="text">
              We streamline essential component engineering tasks, including
              integrating new company part numbers and sourcing component
              information.
              <br />
              Our advanced II-Source tool efficiently manages the process of
              finding and defining alternative parts (crosses) to cataloged
              P/Ns, enhancing cost reduction and availability.
              <br />
              Additionally, we manage Product Change Notices (PCN), End of Life
              (EOL) notifications, BOM and Approved Vendor List (AVL) reviews,
              and ensure compliance with environmental directives such as RoHS,
              REACH, and Conflict Minerals.
            </div>
            <div className="image">
              <img src={Image3} alt="Description 3" />
            </div>
          </div>

          <div className="section-content">
            <div className="text">
              <h3>Supporting Startups and Large Enterprises Alike</h3>
              For startups and small companies lacking robust data management
              systems like ERP or PLM/PDM,{" "}
              <span className="brand">My eDMAC</span> provides value-added
              services designed to enhance data handling, replacing outdated
              systems like Excel with advanced data management tools.
              <br />
              These include embedding engineering processes, optimizing
              component management, and facilitating prototype development.
              <br />
              <br />
              Additionally, <span className="brand">My eDMAC</span> specializes
              in database management and develops tools and modules to meet the
              needs of larger companies.
              <br />
              Our services include managing environmental data, building CAD
              searchable databases, and seamlessly integrating with your
              existing PLM or ERP systems to ensure smooth data synchronization
              and transfer.
            </div>
          </div>

          <div className="section-content">
            <div className="text">
              <h3>Choose My eDMAC</h3>
              Partner with <span className="brand">My eDMAC</span> for
              reliability, expertise, and innovation across all aspects of your
              electronic business operations.
            </div>
          </div>
        </section>
        <div className="container">
          <section className="services">
            <h2>What we do?</h2>
            <div className="services-list">
              <div>Component Engineering Services</div>
              <div>NPI Services</div>
              <div>Engineering Services</div>
              <div>Added Value Services</div>
            </div>
            <Link to="/services">
              <button className="btn-area">See all services</button>
            </Link>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Home;
