import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";

const PartTypeMultiSelector = ({ onSelectPartType }) => {
  const [partTypes, setPartTypes] = useState([]);
  const [selectedPartTypes, setSelectedPartTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPartTypes = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/part-types`);
        const formattedPartTypes = response.data.map(pt => ({ value: pt.id, label: pt.val }));
        setPartTypes(formattedPartTypes);
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch part types:', error);
        setError('Failed to fetch part types');
        setIsLoading(false);
      }
    };

    fetchPartTypes();
  }, []);

  const handleChange = (selectedOptions) => {
    setSelectedPartTypes(selectedOptions);
    const partTypeIds = selectedOptions.map(option => option.value);
    onSelectPartType(partTypeIds);
    console.log("Selected part type conditions:", partTypeIds);
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div style={{ width: "100%" }}>
      <Select style={{ width: "100%" }}
        isMulti
        name="partTypes"
        placeholder="Select part types"
        options={partTypes}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleChange}
        value={selectedPartTypes}
      />
    </div>
  );
};

export default PartTypeMultiSelector;
