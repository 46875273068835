import React from "react";
import { Helmet } from "react-helmet-async";
import VideoComponent from "../components/VideoComponent";
import CapacitorTable from "../components/CapacitorTable";
import "../components/css/SearchForm.css";
import videoFile from "../assets/Video/B.mp4";

const AdvancedSearch = () => {

  // חיפוש באמצעות OpenAI Web Search API 
  const handleSearch = async () => {
    
  };

  return (
    <>
      <Helmet>
        <title>Search Page - My eDMAC</title>
        <meta name="description" content="Welcome to our homepage where you can find various products and services." />
      </Helmet>
      <VideoComponent fileName={videoFile} cropHeight="400px" position="top" />
      <div className="form-container">
        <div className="form-section">
          <h2>Component Search</h2>
          
          <h3>Search by PN</h3>
          <input
            type="text"
            name="pn_search"
            className="form-input"
            placeholder="Search by Company P/N"
          /><br />
          <button className="continue-button" >Search by PN</button><br /><br />

          <h3>Free Text Search</h3>
          <input
            type="text"
            name="stock_search"
            className="form-input"
            placeholder="Free Text"
            
          /><br />
          <button className="continue-button" onClick={() => handleSearch("text")}>Free Text Search</button><br /><br />
        </div>
        
      </div>
      <CapacitorTable />
    </>
  );
};

export default AdvancedSearch;
