import React, { useState } from 'react';
import axios from 'axios';
import '../styles/Contact.css';

function Contact() {
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Phone: '',
    Company: '',
    Position: '',
    Subject: '',
    Message: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Printing form data to the console for debugging
      console.log('Form data:', formData);
      
      // Sending data to the /contactus endpoint
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/contactus`, formData);
      
      // Alerting the user with the response message
      alert(response.data.message);
      
      // Resetting the form data after successful submission
      setFormData({
        Name: '',
        Email: '',
        Phone: '',
        Company: '',
        Position: '',
        Subject: '',
        Message: ''
      });
    } catch (error) {
      // Handling errors and informing the user
      alert('Failed to send contact message: ' + error.message);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="contact">
      <section className="contact-hero">
        {/* Background or image can be added here */}
      </section>
      <div className="container">
        <div className="contact-form">
          <h2>Drop us a line!</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="Name">Name*</label>
              <input type="text" name="Name" value={formData.Name} onChange={handleChange} placeholder="Name" required />
            </div>
            <div className="form-group">
              <label htmlFor="Email">Email*</label>
              <input type="email" name="Email" value={formData.Email} onChange={handleChange} placeholder="Email" required />
            </div>
            <div className="form-group">
              <label htmlFor="Phone">Phone</label>
              <input type="text" name="Phone" value={formData.Phone} onChange={handleChange} placeholder="Phone" />
            </div>
            <div className="form-group">
              <label htmlFor="Company">Company</label>
              <input type="text" name="Company" value={formData.Company} onChange={handleChange} placeholder="Company" />
            </div>
            <div className="form-group">
              <label htmlFor="Position">Position</label>
              <input type="text" name="Position" value={formData.Position} onChange={handleChange} placeholder="Position" />
            </div>
            <div className="form-group">
              <label htmlFor="Subject">Subject*</label>
              <input type="text" name="Subject" value={formData.Subject} onChange={handleChange} placeholder="Subject" required />
            </div>
            <div className="form-group">
              <label htmlFor="Message">Message</label>
              <textarea name="Message" value={formData.Message} onChange={handleChange} placeholder="Message" required></textarea>
            </div>
            <button type="submit" className="contact_button">Send</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
