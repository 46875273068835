import React, { useEffect, useState } from "react";
import axios from "axios";
import "../css/CompanyHeader.css";
import ECIA from "../../assets/ecia.png";

const CompanyHeader = ({ companyId }) => {
  const [company, setCompany] = useState({
    name: "Loading...",
    type: "",
    website: "#",
    ECIA_Member: ''
  });

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/companies/${companyId}`
        );
        console.log("Company details fetched successfully:", response.data);
        let companyType = "Unknown"; // Default value if no conditions are met
        switch(response.data.company_type_id) {
          case 1:
            companyType = "Manufacturer";
            break;
          case 3:
            companyType = "Supplier";
            break;
          case 4:
            companyType = "Subcontractor";
            break;
          case 6:
            companyType = "Component Search Company";
            break;
          default:
            companyType = "Unknown";
        }
        setCompany({
          name: response.data.name_en,
          type: companyType,
          website: response.data.url,
          ECIA_Member: response.data.ECIA_Member
        });
      } catch (error) {
        console.error("Failed to fetch company details:", error);
        setCompany({ name: "Failed to load", type: "", website: "#" });
      }
    };

    fetchCompany();
  }, [companyId]);

  return (
    <>
      <div className="company-type">{company.type}</div>
      <div className="company-header">
        <div className="logo">LOGO</div>
        <div className="company-details">
          <h1 className="company-name">{company.name}</h1>
          {company.ECIA_Member === 'X' && <img src={ECIA} alt="ECIA Member" style={{ position: "absolute", right: 400, width: 80 }} />}
          <a
            href={company.website}
            target="_blank"
            rel="noopener noreferrer"
            className="link-to-site"
          >
            {company.name} URL
          </a>
        </div>
      </div>
    </>
  );
};

export default CompanyHeader;
