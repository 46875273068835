// src/pages/Services.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import content from "../data/data.json";
import "../styles/Services.css";

function Services() {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(content.services);
  }, []);

  return (
    <div className="services">
      <section className="service-hero"></section>
      <div className="services-container">
        <div className="service-card">
          <h3>Component Engineering Services</h3>
          <p>
            Add New P/N (Part Number), Search for Information to the New P/N,
            Search for Regulatory and Environmental data (UL/TUV, Safety, RoHS,
            REACH, Conflict Minerals... Documentation), II Source, Alternate
            Source, Cross Ref, Add new Company P/N to CAD Database, Help
            Purchasing to review suggested alternative to ordered P/N, Help To
            identify received component in warehouse (Find Component Marking
            Info.) and more...
          </p>
          <Link to="/contact">Contact Us For More &gt;</Link>
        </div>
        <div className="service-card">
          <h3>NPI Services</h3>
          <p>
            Help With NPI Processes, Build or Improve Processes, Ability to
            Manage and order Samples for Prototype, Collect data for Design
            stage (Models, Symbols, Footprints, Thermal Data...), Help to manage
            "Time To Prototype"...
          </p>
          <Link to="/contact">Contact Us For More &gt;</Link>
        </div>
        <div className="service-card">
          <h3>Engineering Services</h3>
          <p>
            Building and Managing BOMs, Configuration Management, ECO Management
          </p>
          <Link to="/contact">Contact Us For More &gt;</Link>
        </div>
        <div className="service-card">
          <h3>Added Value Services</h3>
          <p>
            Building Searchable CAD Database that can be synced with company PLM
            or ERP, Unify and organized Description method through the database,
            Preparing Database for ERP and or PLM assimilation by organizing
            statuses, Description, and organizing the AVL to be uploaded,
            building Processes, regulations and Standards to be integrated in
            company procedures and more...
          </p>
          <Link to="/contact">Contact Us For More &gt;</Link>
        </div>
      </div>
      <div className="container" style={{ display: "none" }}>
        <section className="services-content">
          <h1>{data.title}</h1>
          <p>{data.description}</p>
          {data.content &&
            data.content.map((item, index) => (
              <div key={index} className="services-section">
                <h2>{item.heading}</h2>
                <p>{item.text}</p>
              </div>
            ))}
        </section>
      </div>
    </div>
  );
}

export default Services;
