import React, { useEffect, useState } from "react";
import axios from 'axios';

const CompAbout = ({ companyId }) => {
  const [companyInfo, setCompanyInfo] = useState({ about: "Loading...", Company_About_Link: "" });

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/companies/${companyId}`);
        const aboutText = response.data.me_about || "This is preliminary My eDMAC summary data on the company, if we have any information or connection already to the company, data Like capabilities, type of products/Part Types etc. for detail Info., click About Us link below (if we have data or company has such link)";
        setCompanyInfo({
          about: aboutText,
          Company_About_Link: response.data.Company_About_Link || ""
        });
        console.log("Company About Link:", response.data.Company_About_Link);
      } catch (error) {
        console.error("Failed to fetch company info", error);
        setCompanyInfo({
          about: "Failed to load company information."
        });
      }
    };

    if (companyId) {
      fetchCompanyInfo();
    }
  }, [companyId]);

  return (
    <div>
      <h2>About Us</h2>
      <p className="panel-effect">{companyInfo.about}</p>
      {companyInfo.Company_About_Link ? (
        <p><i className="fa-solid fa-link"></i> <a href={companyInfo.Company_About_Link} target="_blank" rel="noopener noreferrer">Company About</a></p>
      ) : (
        <p style={{ color: 'grey' }}><i className="fa-solid fa-link"></i> Company About</p>
      )}
    </div>
  );
};

export default CompAbout;
